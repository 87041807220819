<template>
  <div
    ref="main"
    class="m-main-wrap"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.98)"
    element-loading-text="数据加载中..."
  >
    <div ref="topDom"></div>
    <div :style="{ height: tableHeight + 'px' }">
      <div class="head">
        <h1>
          用戶數據概覽
        </h1>
        <div class="time" id="showTime">{{ pageTime }}</div>
      </div>
      <div class="mainbox">
        <ul class="clearfix">
          <li>
            <div class="boxall" style="height: calc(15% - .15rem)">
              <ul class="row h100 row1">
                <li class="col-4">
                  <div class="bar1">
                    <i class="iconfont icon-view"></i>
                    <h3><span>訪客數</span>{{ userData.vst_num }}个</h3>
                  </div>
                </li>
                <li class="col-4">
                  <div class="bar1">
                    <i class="iconfont icon-gouwuchefill"></i>
                    <h3><span>成交買家數</span>{{ userData.buyer_num }}个</h3>
                  </div>
                </li>
                <li class="col-4">
                  <div class="bar1">
                    <i class="iconfont icon-tags"></i>
                    <h3><span>成交賣家數</span>{{ userData.seller_num }}家</h3>
                  </div>
                </li>
              </ul>
            </div>
            <div class="boxall" style="height: calc(43% - .15rem)">
              <div class="alltitle">
                性別分佈<a href="javascript:;" @click="jump('tab5')">更多>></a>
              </div>
              <div class="boxnav" id="lastecharts">
                <v-chart
                  v-if="options.genderData"
                  style="width: 100%;height: 98%;"
                  :options="options.genderData"
                  :autoresize="true"
                ></v-chart>
              </div>
            </div>
            <div class="boxall" style="height: calc(42% - .15rem)">
              <div class="alltitle">
                年齡分佈<a href="javascript:;" @click="jump('tab6')">更多>></a>
              </div>
              <div class="boxnav" id="echarts3">
                <ul class="row h100 clearfix row2">
                  <li
                    class="col-3"
                    v-for="(item, index) in userData.agearr"
                    :key="item.id"
                  >
                    <div class="tit01">{{ item.name }}</div>
                    <div class="piebox">
                      <v-chart
                        v-if="options['pie0' + (index + 1)]"
                        style="width: 100%;height: 100%;"
                        :options="options['pie0' + (index + 1)]"
                        :autoresize="true"
                      ></v-chart>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="boxall" style="height: calc(20% - .15rem)">
              <ul class="row h100 clearfix">
                <li class="col-6">
                  <div class="sqzs h100">
                    <p>累計用戶數</p>
                    <h1>{{ userData.user_num }}</h1>
                  </div>
                </li>
                <li class="col-6">
                  <div class="sqzs h100">
                    <p>新增用戶數</p>
                    <h1>{{ userData.userday_num }}</h1>
                  </div>
                </li>
                <div class="alltitle nobg">
                  <a href="javascript:;" @click="jump('tab2')">更多>></a>
                </div>
              </ul>
            </div>
            <div class="boxall" style="height: calc(38% - .15rem)">
              <div class="alltitle nobg">
                <a href="javascript:;" @click="jump('tab1')">更多>></a>
              </div>
              <div class="boxnav h100" id="echarts1">
                <v-chart
                  v-if="options.bar01"
                  style="width: 100%;height: 100%;"
                  :options="options.bar01"
                  :autoresize="true"
                ></v-chart>
              </div>
            </div>
            <div class="boxall" style="height: calc(42% - .15rem)">
              <div class="alltitle">
                成交用戶数<a href="javascript:;" @click="jump('tab3')"
                  >更多>></a
                >
              </div>
              <div class="boxnav" id="echarts2">
                <v-chart
                  v-if="options.line01"
                  style="width: 100%;height: 98%;"
                  :options="options.line01"
                  :autoresize="true"
                ></v-chart>
              </div>
            </div>
          </li>
          <li>
            <div class="boxall" style="height: calc(15% - .15rem)">
              <div class="box-title">排行榜單</div>
            </div>
            <div class="boxall" style="height: calc(42% - .15rem)">
              <div class="alltitle">
                成交量排行<a href="javascript:;" @click="jump('tab9')"
                  >更多>></a
                >
              </div>
              <div class=" boxnav paim">
                <ul class="h100">
                  <li v-for="(it, index) in userData.vrarr" :key="index">
                    <span>{{ index + 1 }}</span>
                    <div class="pmnav">
                      <p>{{ it.nickname }}</p>
                      <div class="pmbar">
                        <span class="border-bar" :title="it.percent + '%'">
                          <span
                            class="inner-bar"
                            :style="{ width: it.percent + '%' }"
                          ></span>
                        </span>
                        <i>{{ it.total }}</i>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="boxall" style="height: calc(42% - .15rem)">
              <div class="alltitle">
                成交額排行<a href="javascript:;" @click="jump('tab10')"
                  >更多>></a
                >
              </div>
              <div class="boxnav paim">
                <ul class="h100">
                  <li v-for="(it, index) in userData.rtvarr" :key="index">
                    <span>{{ index + 1 }}</span>
                    <div class="pmnav">
                      <p>{{ it.nickname }}</p>
                      <div class="pmbar">
                        <span class="border-bar" :title="it.percent + '%'">
                          <span
                            class="inner-bar"
                            :style="{ width: it.percent + '%' }"
                          ></span>
                        </span>
                        <i>{{ it.price }}HK$</i>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import dates from '@/utils/dates.js'
import { calConHeight } from '@/common/tool'
/** ecahrs */
import ECharts from 'vue-echarts'
import echarts from 'echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/component/title'
import optionFuns, { color2, color } from '../js/options'
export default {
  components: { 'v-chart': ECharts },
  data() {
    return {
      loading: true,
      timer: null,
      pageTime: '',
      tableHeight: 400,
      userData: {},
      options: {
        genderData: null,
        pie01: null,
        pie02: null,
        pie03: null,
        bar01: null,
        line01: null,
      },
    }
  },
  created() {
    this.getUserLds()
  },
  mounted() {
    // this.showTime()
    this.$nextTick(() => {
      this.calRem()
      this.tableHeight = calConHeight(this)
    })
  },
  methods: {
    jump(tab) {
      this.$router.push({ name: 'statisticsUserDetail', query: { tab } })
    },
    async getUserLds() {
      this.loading = true
      let res = await this.api.statistics.getUserLds()
      if (res && res.code == 0) {
        this.userData = res.data
        this.setGenders()
        this.getAgeDatas()
        this.setBar01()
        this.setLine01()
        this.setRank()
      }
      this.loading = false
    },
    setRank() {
      if (
        Object.prototype.toString.call(this.userData.vrarr) == '[object Array]'
      ) {
        let total = 0
        this.userData.vrarr.forEach((it) => {
          total += it.total
        })
        this.userData.vrarr.forEach((it) => {
          it.percent = ((it.total / total) * 100).toFixed(2)
        })
      }
      if (
        Object.prototype.toString.call(this.userData.rtvarr) == '[object Array]'
      ) {
        let total = 0
        this.userData.rtvarr.forEach((it) => {
          total += it.price * 1
        })
        this.userData.rtvarr.forEach((it) => {
          it.percent = ((it.price / total) * 100).toFixed(2)
        })
      }
    },
    setGenders() {
      let arr = []
      if (
        Object.prototype.toString.call(this.userData.sexarr) == '[object Array]'
      ) {
        this.userData.sexarr.forEach((it) => {
          arr.push({
            name: it.name,
            value: it.total,
            percent: it.percent,
          })
        })
        this.options.genderData = {
          tooltip: {
            trigger: 'item',
          },
          color: color2,
          series: [
            {
              type: 'pie',
              radius: ['30%','50%'],
              data: arr,
              label: {
                formatter: (params) => {
                  return params.name + '：' + params.data.value
                },
              },
              tooltip: {
                formatter: (params) => {
                  return (
                    params.data.name +
                    '<br />'+
                    '總數：' +
                    params.data.value +
                    '<br />佔比：' +
                    params.data.percent +
                    '%'
                  )
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }
      }
    },
    getAgeDatas() {
      let colors = ['#eaff00', '#ea4d4d', '#395ee6']
      if (
        Object.prototype.toString.call(this.userData.agearr) == '[object Array]'
      ) {
        this.userData.agearr.forEach((it, index) => {
          let num = index * 1 + 1
          this.options['pie0' + num] = optionFuns.getPieOptions({
            name: 'pie0' + num,
            val: it.percent,
            color: '#49bcf7',
            data: [
              {
                value: it.percent,
                name: it.name,
                itemStyle: {
                  normal: {
                    color: colors[index % 3],
                    label: {
                      show: false,
                    },
                    labelLine: {
                      show: false,
                    },
                  },
                },
              },
              {
                name: '',
                value: 100 - it.percent,
              },
            ],
          })
        })
      }
    },
    setBar01() {
      if (
        Object.prototype.toString.call(this.userData.vstdayarr) ==
        '[object Array]'
      ) {
        let arr = [],
          arr2 = [],
          xData = []
        this.userData.vstdayarr.forEach((it, index) => {
          arr.push(it.vst_total)
          arr2.push(it.user_total)
          xData.push(it.date)
        })
        let series = [
          {
            name: '訪客數',
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#8bd46e',
                  },
                  {
                    offset: 1,
                    color: '#03b48e',
                  },
                ]),
                barBorderRadius: 11,
              },
            },
            data: arr,
          },
          {
            name: '新增用戶數',
            type: 'bar',
            barWidth: '15%',
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: '#3893e5',
                  },
                  {
                    offset: 1,
                    color: '#248ff7',
                  },
                ]),
                barBorderRadius: 11,
              },
            },
            data: arr2,
          },
        ]
        this.options.bar01 = optionFuns.getBarOptions({
          series,
          xData,
        })
      }
    },
    setLine01() {
      let arr = [],
        xData = [],
        series = []
      if (
        Object.prototype.toString.call(this.userData.cad_num) ==
        '[object Array]'
      ) {
        this.userData.cad_num.forEach((it) => {
          arr.push(it.total)
          xData.push(it.date)
        })
        series = [
          {
            type: 'line',
            smooth: true,
            symbol: 'circle',
            symbolSize: 10,
            showSymbol: true,
            lineStyle: {
              normal: {
                color: 'rgba(228, 228, 126, 1)',
                width: 2,
              },
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(228, 228, 126, .8)',
                    },
                    {
                      offset: 0.8,
                      color: 'rgba(228, 228, 126, 0.1)',
                    },
                  ],
                  false
                ),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
              },
            },
            itemStyle: {
              normal: {
                color: '#dddc6b',
                borderColor: 'rgba(221, 220, 107, .1)',
                borderWidth: 12,
              },
            },
            data: arr,
          },
        ]
        this.options.line01 = optionFuns.getLineOptions({ series, xData })
      }
    },
    calRem() {
      var docEl = document.documentElement,
        recalc = function() {
          var clientWidth = docEl.clientWidth - 180
          if (!clientWidth) return
          docEl.style.fontSize = clientWidth / 20 + 'px'
        }
      recalc()
      window.addEventListener('resize', recalc, false)
    },
    showTime() {
      this.timer = setTimeout(time, 1000) //開始运行
      let self = this
      function time() {
        let dt = new Date()
        self.pageTime = dates.formatDate(dt, 'yyyy年MM月dd日hh時mm分ss秒')
        self.timer = setTimeout(time, 1000) //设定定时器，循环运行
      }
    },
  },
  unmounted() {
    clearTimeout(this.timer)
  },
  destroyed() {
    clearTimeout(this.timer)
  },
}
</script>
<style scoped>
.iconfont {
  color: #fff;
  font-size: 0.3rem;
}
.box-title {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-size: 0.4rem;
  line-height: 1;
  padding-top: 0.3rem;
}
.m-main-wrap {
  background: #000d4a url(~@/assets/images/bg.jpg) center center;
  background-size: cover;
  color: #fff;
  font-size: 0.1rem;
}
.pulll_left {
  float: left;
}
.pulll_right {
  float: right;
}
/*谷哥滚动条样式*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  background-color: #5bc0de;
}
::-webkit-scrollbar-track {
  background-color: #ddd;
}
/***/

.loading {
  position: fixed;
  left: 0;
  top: 0;
  font-size: 18px;
  z-index: 100000000;
  width: 100%;
  height: 100%;
  background: #1a1a1c;
  text-align: center;
}
.loadbox {
  position: absolute;
  width: 160px;
  height: 150px;
  color: #324e93;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -75px;
}
.loadbox img {
  margin: 10px auto;
  display: block;
  width: 40px;
}

.head {
  height: 1.05rem;
  background: url(~@/assets/images/head_bg.png) no-repeat center center;
  background-size: 100% 100%;
  position: relative;
}
.head h1 {
  color: #fff;
  text-align: center;
  font-size: 0.4rem;
  line-height: 0.8rem;
  letter-spacing: -1px;
}
.head h1 img {
  width: 1.5rem;
  display: inline-block;
  vertical-align: middle;
}
.time {
  position: absolute;
  right: 0.15rem;
  top: 0;
  line-height: 0.75rem;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.26rem;
  padding-right: 0.1rem;
}

.mainbox {
  padding: 0 0.2rem 0rem 0.2rem;
  height: 90%;
}
.mainbox > ul {
  margin-left: -0.1rem;
  margin-right: -0.1rem;
  height: 100%;
}
.mainbox > ul > li {
  float: left;
  padding: 0 0.1rem;
  height: 100%;
  width: 30%;
}
.mainbox > ul > li:nth-child(2) {
  width: 40%;
}

.boxall {
  padding: 0 0.2rem 0.2rem 0.2rem;
  background: rgba(6, 48, 109, 0.5);
  position: relative;
  margin-bottom: 0.15rem;
  z-index: 10;
}
.alltitle {
  font-size: 0.2rem;
  color: #fff;
  line-height: 0.5rem;
  position: relative;
  padding-left: 0.15rem;
}
.alltitle a {
  position: absolute;
  right: 0;
  color: #fff;
  z-index: 2;
}
.alltitle:before {
  position: absolute;
  height: 0.2rem;
  width: 4px;
  background: #49bcf7;
  border-radius: 5px;
  content: '';
  left: 0;
  top: 50%;
  margin-top: -0.1rem;
}
.nobg.alltitle:before {
  display: none;
}
.boxnav {
  height: calc(100% - 0.5rem);
}
.row > li {
  float: left;
  height: 100%;
}
.col-6 {
  width: 50%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33333%;
}
.h100 {
  height: 100% !important;
}
.tit01 {
  text-align: center;
  color: white;
  font-size: 0.16rem;
  padding: 0.3rem 0 0.05rem 0;
}
.piebox {
  /* height: calc(100% - 0.5rem); */
  position: relative;
}
.row2 li {
  height: auto;
}
.row1 .bar1 {
  height: 100%;
  background: url(~@/assets/images/bg01.png) no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.bar1 img {
  width: 0.4rem;
  margin-right: 0.15rem;
}
.bar1 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.bar1 span {
  color: #fff;
  font-size: 0.16rem;
  display: block;
  font-weight: normal;
}
.bar1 h3 {
  color: #fef000;
  font-size: 0.22rem;
}
.row1 li {
  padding: 0.2rem 0.1rem 0 0.1rem;
}
.row1 {
  margin-left: -0.1rem;
  margin-right: -0.1rem;
}
.row1 .bar2 {
  background: url(~@/assets/images/bg02.png) no-repeat;
  background-size: 100% 100%;
}
.row1 .bar2 h3 {
  text-align: right;
}
.paim li > span {
  width: 0.3rem;
  height: 0.3rem;
  text-align: center;
  line-height: 0.3rem;
  background: #878787;
  border-radius: 0.05rem;
  margin-right: 0.1rem;
  font-size: 0.16rem;
}
.paim li:nth-child(1) > span {
  background: #ed405d;
}
.paim li:nth-child(2) > span {
  background: #f78c44;
}
.paim li:nth-child(3) > span {
  background: #49bcf7;
}
.paim li {
  display: flex;
  align-items: center;
  height: 20%;
}

.pmbar {
  position: relative;
  display: flex;
}

.pmbar .inner-bar {
  background: linear-gradient(to right, #14b6ff, #9cefff);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-radius: 0.5rem;
}
.pmbar .border-bar {
  width: 80%;
  border: 1px solid #00deff;
  background: none;
  position: relative;
  height: 0.15rem;
  border-radius: 0.5rem;
}
.pmbar i {
  line-height: 0.2rem;
  font-style: normal;
  padding-left: 0.1rem;
  color: #49bcf7;
}
.pmnav {
  width: calc(100% - 0.5rem);
}
.pmnav p {
  color: #fff;
  opacity: 0.6;
  font-size: 0.15rem;
  padding-bottom: 0.05rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sqzs {
  margin-right: 0.2rem;
}
.sqzs p {
  padding: 0.2rem 0;
  font-size: 0.22rem;
}
.sqzs h1 {
  height: calc(100% - 0.65rem);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  color: #fef000;
  font-family: Gotham, 'Helvetica Neue', Helvetica, Arial, 'sans-serif';
  font-weight: normal;
  letter-spacing: 2px;
  font-size: 0.85rem;
  justify-content: center;
  padding-bottom: 0.05rem;
}

.sec li {
  height: 33.3333%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sec li > div:nth-child(1) {
  width: 85%;
}
.sec li > div:nth-child(2) {
}
.sec .bar2 {
  width: 70%;
  margin: 0.1rem 0;
  display: inline-block;
  vertical-align: middle;
  border: 1px solid #00deff;
  height: 0.3rem;
  padding: 0.07rem;
  border-radius: 1rem;
}
.sec .bar2 span {
  display: block;
  height: 100%;
  background: linear-gradient(to right, #14b6ff, #9cefff);
  border-radius: 1rem;
}

.sec p {
  display: flex;
  align-items: center;
  font-size: 0.26rem;
}
.sec p img {
  width: 0.35rem;
  margin-right: 0.15rem;
}
.barnav span {
  text-align: center;
  color: #f7e80d;
  font-size: 0.24rem;
  padding-left: 0.1rem;
  line-height: 0.3rem;
}

.zaf {
  text-align: center;
}
.zaf p {
  justify-content: center;
}
.zaf p img {
  width: 0.18rem;
  margin-right: 0;
}
.zaf span {
  color: #00d3ff;
  font-family: Impact;
  font-size: 0.35rem;
}
.zaf span i {
  font-style: normal;
  font-size: 0.18rem;
}
</style>
